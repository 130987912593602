import './assets/style.scss';  
import { dataContent } from '../../content';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let Project2 = () => {
    let data = dataContent.projectData;
    
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    let listProjects = data.map((dataProjects) => (
        <Link to={`/project/${dataProjects.id}`} className="card-slide" key={dataProjects.id} id={dataProjects.id}>
            <div className="card-img">
                <img 
                    src={dataProjects.image[0]} 
                    alt="Présentation projet" 
                />
            </div>
            <h2 className="card-title">
                {dataProjects.title}
            </h2>
            <p className='card-content'>
                {dataProjects.sliderDesciption}
            </p>
            <h3 className="card-lang">Langage utilisés</h3>
            <div className="card-skills">
                {dataProjects.skills.map((skill, index) => (
                    <p key={index} className="skill-item">
                        {skill}
                    </p>
                ))}
            </div>
        </Link>
    ));

    return (
        <div className='projectSect'>
            <Slider {...settings}>
                {listProjects}
            </Slider>
        </div>
    );
}

export default Project2;

import './assets/style.scss'
import React, { useState } from 'react';  
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';

import { init, sendForm } from 'emailjs-com';
init('INJl41LTUfTT87C4G');

let Form = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isSent, setIsSent] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const onSubmit = data => {
      setIsSending(true);
        const { Email, Message } = data;
        console.log("Email:", Email);
        console.log("Message:", Message);
      
        sendForm('Contact_Form', 'template_n6ib9t8', '#contact-form')
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
          if (response.status === 200) {
            setIsSending(false);
            setIsSent(true);
          }
        }, function(error) {
          console.log('FAILED...', error);
          setIsSending(false);
        });
      };

    return (
        
        <form className='form' onSubmit={handleSubmit(onSubmit)} id='contact-form'>
            <label htmlFor="NomPrenom">Nom et Prénom*</label>
            <input type="text" id="NomPrenom" {...register("First name", { required: true, maxLength: 80 })} />
            
            <label htmlFor="Email">Email*</label>
            <input type="text" id="Email" {...register("Email", { required: true, pattern: /^\S+@\S+$/i })} />
            {errors.Email && errors.Email.type === "pattern" && (
                <span className="error">Adresse email invalide</span>
            )}

            <label htmlFor="mobileNumb">Numéro de téléphone</label>
            <input type="tel" id="mobileNumb" {...register("Mobile number", { required: false, pattern: /^[0-9]{6,12}$/i })} />
            {errors["Mobile number"] && errors["Mobile number"].type === "pattern" && (
                <span className="error">Numéro de téléphone invalide</span>
            )}

            <label htmlFor="Message">Message*</label>
            <textarea type="text" id="Message" {...register("Message", { required: true })} />

            {isSending ? (
                <motion.input 
                    type="button"
                    value="En cours d'envoi..."
                    disabled
                />
            ) : isSent ? (
                <motion.input 
                    type="submit"
                    value="Envoyé avec succès !"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                />
            ) : (
                <motion.input 
                    type="submit" 
                    value="Envoyer"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                />
            )}
        </form>
    );
}

export default Form;

import './assets/style.scss'

let Footer = () => {
    return (
        <div className='footer'>
            <div className="footer-content">Icones créés par, Riajulislam, Laisa Islam Ani et Fathema Khanom sur Flaticon.</div>
        </div>
    )
}

export  default Footer